
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { PessoaService } from "@/core/services/cadastros";
import {EmpreendimentoService, ContratoService} from "@/core/services/cadastros";
import { SituacaoReceitaParcelaService, TipoReceitaService } from "@/core/services/financeiro";
import { EmpresaService } from "@/core/services/compras/EmpresaService";

@Component
export default class CfgBoleto extends Vue {
  filter: {
    empreendimentoId: any; 
    boletoGerado:any;
    numeroContrato: number; 
    pagamentoAutomatico:any;
    clienteId: number; 
    tipoReceitaId:any; 
    dataVencimentoInicial: string; 
    dataVencimentoFinal: string; 
    empresaId: any;
    sequenciaContrato:number;
    dataCobrancaInicial:string; 
    dataCobrancaFinal:string; 
    loteId:number;  
    quadraId:number;
    situacaoParcelaId:any;
  } = { 
    empreendimentoId: 0,
    boletoGerado:"",
    numeroContrato: 0,
    pagamentoAutomatico:"",
    clienteId: 0,
    tipoReceitaId:0,
    dataVencimentoInicial: "",
    dataVencimentoFinal: "",
    empresaId: 0,
    sequenciaContrato:0,
    dataCobrancaInicial:"",
    dataCobrancaFinal: "",
    loteId:0,
    quadraId:0,
    situacaoParcelaId:0
  };

  valid = true;

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];


  SimNao = [
    {id:1, nome:"Sim", condicao:true},
    {id:2, nome:"Não", condicao:false},
  ]
  pessoas:any = [];
  empreendimentos:any = [];

  tiposReceitas = [];
  situacoes = [];
  empresas = [];


  get selecionarTodosEmpreendimentos(){
    return this.filter.empreendimentoId.length === this.empreendimentos.length
  }

  get SelecionarAlgunsEmpreendimentos(){
    return this.filter.empreendimentoId.length > 0 && !this.selecionarTodosEmpreendimentos
  }

  get iconEmpreendimento(){
    if (this.selecionarTodosEmpreendimentos) return 'mdi-close-box'
    if (this.SelecionarAlgunsEmpreendimentos) return 'mdi-minus-box'
    return 'mdi-checkbox-blank-outline'
  }

  get textEmpreendimento(){
    if (this.selecionarTodosEmpreendimentos) return 'Desmarcar'
    if (this.SelecionarAlgunsEmpreendimentos) return 'Selecionando...'
    return 'Selecionar todos'
  }

  SelecionarEmpreendimento() {
    this.$nextTick(() => {
        if (this.selecionarTodosEmpreendimentos) {
        this.filter.empreendimentoId = []
        } else {
          this.filter.empreendimentoId = this.empreendimentos.slice().map((x:any)=>x.id)
        }
    })
  }

  /* TIPO DE RECEITA  */
  SelecionarTipoReceita() {
    this.$nextTick(() => {
        if (this.selecionarTodos) {
        this.filter.tipoReceitaId = []
        } else {
          this.filter.tipoReceitaId = this.tiposReceitas.slice().map((x:any)=>x.id)
        }
    }) 
  }

  get selecionarTodos(){
    return this.filter.tipoReceitaId.length === this.tiposReceitas.length
  }

  get SelecionarAlguns(){
    return this.filter.tipoReceitaId.length > 0 && !this.selecionarTodos
  }

  get icon(){
    if (this.selecionarTodos) return 'mdi-close-box'
    if (this.SelecionarAlguns) return 'mdi-minus-box'
    return 'mdi-checkbox-blank-outline'
  }

  get text(){
    if (this.selecionarTodos) return 'Desmarcar'
    if (this.SelecionarAlguns) return 'Selecionando...'
    return 'Selecionar todos'
  }


  SelecionarEmpresa(){
    this.$nextTick(() => {
      if (this.selecionarTodosEmpresas) {
        this.filter.empresaId = []
      } else {
        this.filter.empresaId = this.empresas.slice().map((x:any)=>x.id)
      }
    })
  }

  get selecionarTodosEmpresas(){
    return this.filter.empresaId.length === this.empresas.length
  }

  get SelecionarAlgunsEmpresas(){
    return this.filter.empresaId.length > 0 && !this.selecionarTodosEmpresas
  }

  get iconEmpresa(){
    if (this.selecionarTodosEmpresas) return 'mdi-close-box'
    if (this.SelecionarAlgunsEmpresas) return 'mdi-minus-box'
    return 'mdi-checkbox-blank-outline'
  }

  get textEmpresa(){
    if (this.selecionarTodosEmpresas) return 'Desmarcar'
    if (this.SelecionarAlgunsEmpresas) return 'Selecionando...'
    return 'Selecionar todos'
  } 


  SelecionarSituacao(){ 
       this.$nextTick(() => {
          if (this.selecionarTodosSituacao) {
          this.filter.situacaoParcelaId = []
          } else {
            this.filter.situacaoParcelaId = this.situacoes.slice().map((x:any)=>x.id)
          }
      }) 
  }

  get selecionarTodosSituacao(){
    return this.filter.situacaoParcelaId.length === this.situacoes.length
  }

  get SelecionarAlgunsSituacao(){
    return this.filter.situacaoParcelaId.length > 0 && !this.selecionarTodosSituacao
  }

  get iconSituacao(){
    if (this.selecionarTodosSituacao) return 'mdi-close-box'
    if (this.SelecionarAlgunsSituacao) return 'mdi-minus-box'
    return 'mdi-checkbox-blank-outline'
  }

  get textSituacao(){
    if (this.selecionarTodosSituacao) return 'Desmarcar'
    if (this.SelecionarAlgunsSituacao) return 'Selecionando...'
    return 'Selecionar todos'
  }

  mounted() {
    new EmpresaService().Listar(-1, -1, ['nomeFantasia'],[],'',[], '', '' , 'Id, nomeFantasia', '').then(res =>{
      this.empresas = res.data.items
    })

    new SituacaoReceitaParcelaService().ListarTudo().then(
    res => {
      this.situacoes = res.data.items;
    })
 
    new TipoReceitaService().ListarTudo().then(
    res => {
      this.tiposReceitas = res.data.items
    })

    const pessoaService = new PessoaService();
    pessoaService.Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome,cnpjCpf, nomeCnpjCpf', '').then(
    (res) => {
      this.pessoas = res.data.items;
    });

    const empreendimentoService = new EmpreendimentoService();
    empreendimentoService.Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome', '').then(
    (res) => {
      this.empreendimentos = res.data.items;
    });
  } 
  
  Visualizar() {
    if((!this.filter.dataVencimentoInicial || !this.filter.dataVencimentoFinal) && (!this.filter.dataCobrancaInicial || !this.filter.dataCobrancaFinal)){
        this.$swal("Aviso", "Obrigatório preencher início e fim de pelo menos uma das datas.", "warning");
        return true;
    }    
    let routeLink = this.$router.resolve({ 
      
    name: "RelBoleto",
      query: {
        empreendimentoId: this.filter.empreendimentoId ? this.filter.empreendimentoId.toString() : "",
        boletoGerado: this.filter.boletoGerado != null ? this.filter.boletoGerado : "", 
        pagamentoAutomatico: this.filter.pagamentoAutomatico != null ? this.filter.pagamentoAutomatico : "",
        clienteId: this.filter.clienteId ? this.filter.clienteId.toString() : "",
        tipoReceitaId: this.filter.tipoReceitaId ? this.filter.tipoReceitaId.toString() : "",
        dataVencimentoInicial: this.filter.dataVencimentoInicial ? this.filter.dataVencimentoInicial.toString() : "",
        dataVencimentoFinal: this.filter.dataVencimentoFinal ? this.filter.dataVencimentoFinal.toString() : "",
        empresaId: this.filter.empresaId ? this.filter.empresaId.toString() : "",
        sequenciaContrato: this.filter.sequenciaContrato ? this.filter.sequenciaContrato.toString() : "",
        dataCobrancaInicial: this.filter.dataCobrancaInicial ? this.filter.dataCobrancaInicial.toString() : "",
        dataCobrancaFinal:  this.filter.dataCobrancaFinal ? this.filter.dataCobrancaFinal.toString() : "",
        situacaoParcelaId: this.filter.situacaoParcelaId ? this.filter.situacaoParcelaId.toString() : "",
       },
    });
    window.open(routeLink.href, "_blank");
  }
}
